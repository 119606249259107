function webKitCallEvent(type, message) {
  var messageObj = {type: type, message: message}
  var stringifiedMessageObj = JSON.stringify(messageObj)
  if (window.webkit && window.webkit.messageHandlers) {
    window.webkit.messageHandlers.cordova_iab.postMessage(stringifiedMessageObj);
  } else {
    window.cordova_iab.postMessage(stringifiedMessageObj);
  }
}


function callAngularFunction(data) {
  window.angularComponentReference.zone.run(() => { window.angularComponentReference.loadAngularFunction(data);});
}
